@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&family=Josefin+Sans:wght@100;200;300;400;500;600;700&display=swap");

@font-face {
  font-family: "vazir";
  src: url("../public/fonts/Vazir.eot");
  src: url("../public/fonts/Vazir.eot?#iefix") format("embedded-opentype"),
    url("../public/fonts/Vazir.woff2") format("woff2"),
    url("../public/fonts/Vazir.woff") format("woff"),
    url("../public/fonts/Vazir.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "roboto";
  src: url("../public/fonts/Roboto-Regular.eot");
  src: url("../public/fonts/Roboto-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../public/fonts/Roboto-Regular.woff2") format("woff2"),
    url("../public/fonts/Roboto-Regular.woff") format("woff"),
    url("../public/fonts/Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.leaflet-container { 
  height: 100svh;
}

html,
body,
#root,
.app {
  height: 100%;
  width: 100%;
  overflow: hidden;
  font-family: "YekanBakhFaNum-Regular";
  /* prevent text selection */
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

input,
textarea,
button,
select,
a,
div {
  -webkit-tap-highlight-color: transparent;
}

.error-message {
  font-size: 10px;
  color: red;
}

.multiSelectAdmin {
  height: 50px;
}

.multiSelectAdmin .dropdown-heading {
  height: 43px !important;
}
.multiSelectAdmin .dropdown-container {
  border-radius: 7px !important;
  background-color: "red" !important;
}
