/* Loading.css */
.loading-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
  background-color: #141332; /* Dark background */
}

.dot {
  width: 12px;
  height: 12px;
  margin: 0 5px;
  border-radius: 50%;
  animation: bounce 1.4s infinite ease-in-out both;
}

.dot.blue {
  background-color: #2f80ed; /* Blue */
}

.dot.red {
  background-color: #fc4949; /* Red */
  animation-delay: -0.1s;
}

.dot.green {
  background-color: #5fbd15; /* Green */
  animation-delay: -0.2s;
}

.dot.yellow {
  background-color: #ffee00; /* Yellow */
  animation-delay: -0.3s;
}

@keyframes bounce {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
